<script>
import { required, numeric } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Content from '@/services/Content'
import Swal from "sweetalert2";
import vueDropzone from "vue2-dropzone";

export default {
  components: { vueDropzone },
  props: ['verticalImage'],
  data() {
    return {
      brand_name: "",
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      selectLogo: "",
      selectHeader: "",
      brands: null,
      productsBrands: [],
      display_order: "",
      editableImage: "",
      dropzoneOptions_vertical_image: {
        url: "/",
        autoProcessQueue: false,
        addRemoveLinks: true,
        thumbnailWidth: 300,
        thumbnailHeight: 200,
        thumbnailMethod: "contain",
        maxFilesize: 2,
        acceptedFiles: "image/*",
        maxFiles: 1,
      },
    };
  },

  watch: {
    verticalImage: {
      handler(newVal) {
        this.editableImage = { ...newVal };
      },
      immediate: true,
    },
  },

  methods: {
    async submitForm() {
      this.tryingToSubmit = true;
      this.showLoader = true;

      try {
        let formData = new FormData();
        formData.append('type', this.editableImage.type);
        formData.append('content_type', this.editableImage.content_type);
        formData.append('csrf_token', this.csrf_token);
        formData.append('_method', 'PATCH');

        const logoFile = this.$refs.myVueDropzone_vertical_image.getAcceptedFiles()[0];
        if (logoFile) {
          formData.append('vertical_image', logoFile);
        }
        const response = await Content.updateFeed(this.editableImage.id, formData);

        if (response.success === false) {
          Swal.fire("Error", response.error || "Failed to update image. Please try again.", "error");
        } else {
          Swal.fire("Success", "Image update  successfully!", "success");
          this.closeModal();
          this.refreshData();
        }

      } catch (error) {
        // Catch any network or unexpected errors
        console.error("Failed to create brand:", error);
        Swal.fire("Error", error.response?.data?.error || "Failed to update image. Please try again.", "error");
      } finally {
        this.tryingToSubmit = false;
        this.showLoader = false;
      }
    },


    resetForm() {
      if (this.$refs.myVueDropzone_vertical_image) {
        this.$refs.myVueDropzone_vertical_image.removeAllFiles();
      }
    },
    setData() {
      this.preloadDropzone()
    },

    closeModal() {
      this.resetForm();
      this.showModal = false;
    },

    refreshData() {
      this.$emit("onRefresh");
    },
    removeFile() {
      const dropzoneInstance = this.$refs.myVueDropzone_vertical_image.dropzone;

      if (dropzoneInstance && dropzoneInstance.files.length > 0) {
        const fileToRemove = dropzoneInstance.files[0];
        dropzoneInstance.removeFile(fileToRemove);

        this.editableImage.vertical_image = null;
      } else {
        console.warn("No files to remove or Dropzone is not initialized.");
      }
    },
    preloadDropzone() {
      // Access Dropzone instance via the ref
      const dropzone = this.$refs.myVueDropzone_vertical_image.dropzone;

      // Clear existing files in Dropzone (if any)
      dropzone.removeAllFiles(true);

      const fileUrl = this.editableImage.vertical_image;
      if (fileUrl) {
        // Create a mock file object
        const mockFile = {
          name: "Vertical Image", // You can customize this
          size: 12345, // Mock size; not used but required
          type: "image/png", // Adjust MIME type as necessary
        };

        // Add the mock file to Dropzone
        dropzone.emit("addedfile", mockFile);
        dropzone.emit("thumbnail", mockFile, fileUrl);
        dropzone.emit("complete", mockFile);

        // Optionally, set the file as accepted (if needed for your logic)
        mockFile.status = dropzone.ADDED;
        dropzone.files.push(mockFile);
      }
    },
  },
};
</script>

<template>
  <b-modal
      @shown="setData"
      @hidden="resetForm"
      id="update_vertical_image"
      size="l"
      v-model="showModal"
      title="Edit Vertical Image"
      title-class="font-18"
  >
    <template>
      <div class="row">
        <div>
          <b-card-text>
            
            <b-form-group label="Vertical Image" class="mb-3">
              <vue-dropzone
                  ref="myVueDropzone_vertical_image"
                  id="logo-dropzone"
                  :options="dropzoneOptions_vertical_image"
              ></vue-dropzone>
              <button class="btn btn-danger mt-2" @click="removeFile">Remove Vertical Image</button>
            </b-form-group>
          </b-card-text>
        </div>
      </div>
     </template>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="submitForm" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>
<style>
/* Hide the success and remove icons in Dropzone */
.dropzone .dz-success-mark,
.dropzone .dz-error-mark {
  display: none; /* Hide the success (checkmark) and error icons */
}

.dropzone .dz-remove {
  display: none; /* Hide the 'Remove' link */
}

#logo-dropzone .dz-preview .dz-image {
  width: 100% !important;  /* Make the image container take full width */
  height: auto !important; /* Maintain aspect ratio */
  max-height: 200px;       /* Optional: Set a max height */
  display: flex;
  align-items: center;
  justify-content: center;
}

#logo-dropzone .dz-preview .dz-image img {
  width: 100% !important; /* Make the image itself take full width */
  //height: 200px !important;
  max-height: 200px !important;
  object-fit: contain;    /* Ensures image fits well */
}

</style>
