<script>
import { required, numeric } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Content from '@/services/Content'
import Swal from "sweetalert2";
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


export default {
  components: { validationMessages, flatPickr },
  props: ['pinDate'],

  data() {
    return {
      date:'',
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      clickedDate: "",
      showLoader: false,
      datetimeconfig: {
        enableTime: true,
        time_24hr: true,
        dateFormat: "Y-m-d H:i",
        minDate: "today",
        minuteIncrement: 1,
        defaultDate: new Date(),
        plugins: [new ConfirmDatePlugin({})]
      },
      id: this.$route.params.id,
      csrf_token: localStorage.getItem("csrf_token"),
    };
  },
  watch: {
    pinDate: {
      handler(newVal) {
        if (newVal && newVal.pin_end_time) {

          let parsedDate = this.parseDate(newVal.pin_end_time);
          if (parsedDate) {
            this.date = parsedDate;
          } else {
            this.date = "";
          }
        } else {
          this.date = "";
        }
      },
      immediate: true,
    },
    showModal(newValue) {
      if (newValue && this.pinDate?.pin_end_time) {
        this.date = this.parseDate(this.pinDate.pin_end_time);
      }
    },
  },
  validations: {
    date: { required },
  },

  methods: {
    async submitForm() {
      this.tryingToSubmit = true;

      if (this.$v.$invalid) {
        this.submitted = true;
        this.tryingToSubmit = false;
        return;
      }
      try {
        let formData = new FormData();
        formData.append('pin_end_time', this.date);
        formData.append('type', this.pinDate.type);
        formData.append('content_type', this.pinDate.content_type);
        formData.append('csrf_token', this.csrf_token);
        formData.append('_method', 'PATCH');
        const response = await Content.updateFeed(this.pinDate.id, formData);
        if (response.success === false) {
          Swal.fire("Error", response.error || "Failed to Pin Update. Please try again.", "error");
        } else {
          Swal.fire("Success", "Pin Update successfully!", "success");
          this.closeModal();
          this.refreshData();
        }

      } catch (error) {
        // Catch any network or unexpected errors
        console.error("Failed to create brand:", error);
        Swal.fire("Error", error.response?.data?.error || "Failed to Pin Update. Please try again.", "error");
      } finally {
        this.tryingToSubmit = false;
        this.showLoader = false;
      }
    },
    parseDate(dateString) {
      if (!dateString) return null;

      if (dateString instanceof Date) {
        return dateString;
      }

      if (!isNaN(dateString)) {
        return new Date(Number(dateString));
      }

      let parsedDate = new Date(dateString);
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }

      let dateParts = dateString.split("-");
      if (dateParts.length === 3) {
        return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      }
      return null;
    },

    resetForm() {
      this.date = null;
      this.$v.$reset();
    },

    closeModal() {
      this.resetForm();
      this.showModal = false;
    },
    refreshData() {
      this.$emit("onRefresh");
    },
  },
};
</script>

<template>
  <b-modal
      @hidden="resetForm"
      id="update_pin"
      size="sm"
      v-model="showModal"
      title="Update Pin"
      title-class="font-18"
  >
    <form @submit.prevent="submitForm">
      <div class="row">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <div v-if="showLoader" class="card-body d-flex align-items-center justify-content-center">
          <b-spinner large></b-spinner>
        </div>
        <div v-else>
          <b-card-text>
            <div class="col-sm-12 col-md-12">
              <b-form-group label="Date" label-for="formrow-date-input" class="mb-3">
                <flat-pickr
                    v-model="date"
                    :config="datetimeconfig"
                    class="form-control"
                />
                <validationMessages
                    v-if="submitted"
                    :fieldName="'Date'"
                    :validationName="$v.date"
                ></validationMessages>
              </b-form-group>
            </div>
          </b-card-text>
        </div>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="submitForm" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>

